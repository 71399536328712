import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import { UpdateContextRequest, UserContextDto } from '@/api/contracts/models'
import QueryKeys from '@/vue-query/QueryKeys'
import { useUserProfileApi } from '../api/useUserProfileApi'

export function useContextUpdate(queryClient: QueryClient) {
   const client = useUserProfileApi()
   const apiFn = (data: UpdateContextRequest) => client.updateContext(data)
   const optimisticFn = (old: UserContextDto, data: UpdateContextRequest) => {
      return { ...data }
   }

   return useOptimisticMutation(
      [QueryKeys.Context],
      queryClient,
      apiFn,
      optimisticFn
   )
}
